import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import Layout from '../../components/layout';

const Container = styled("div")`
${tw`w-full flex justify-center flex-col 2k:py-24 py-24`}
`
const VideoContainer = styled("div")`
${tw`w-full flex justify-center items-center pt-4 2k:pt-10`}
`

const SMtH = () => (
    <StaticQuery
        query={graphql`
      {
        allGraphCmsSasquatchMthVideo {
            edges {
              node {
                title
                youTubeLink
                id
              }
            }
          }
      }
    `}
        render={data =>
            <Layout>
                <Container>
                    <div className="w-full flex justify-center">
                        <div className="w-full desktop:w-4/5 2k:w-2/5 flex flex-col mb-4">
                            <div className="w-full text-3xl tablet:text-4xl tablet:tracking-wide tablet:leading-10 font-extrabold phone:leading-none text-left font-display text-gray-700">
                                The Sasquatch Message to Humanity
                            </div>
                            <div className="w-full text-lg tablet:text-2xl tablet:tracking-wide tablet:leading-10 font-extrabold phone:leading-none text-left font-sans text-blue-400">
                                Read Aloud by Divine Fractal
                            </div>
                        </div>
                    </div>
                    <VideoContainer>
                        <div className="w-full flex flex-row flex-wrap justify-center flex-grow tablet:pb-4 mb-4">
                            <div className="w-4/5 grid grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3 gap-9 2k:gap-6">
                                {data.allGraphCmsSasquatchMthVideo.edges.map((sasquatch) => (
                                <div className="w-full" key={sasquatch.node.id}>
                                    <div className="text-xl font-display font-bold mb-4">
                                        {sasquatch.node.title}
                                    </div>
                                    <div className="w-full embed-youtube shadow-lg">
                                        <iframe width="560" height="315" loading="lazy" src={`https://www.youtube.com/embed/${sasquatch.node.youTubeLink}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" title="intro"></iframe>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    </VideoContainer>
                </Container>
            </Layout>
        }>
    </StaticQuery>
)

export default SMtH;
